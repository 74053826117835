export enum MontageArbeitnehmer {
  ja = 'Ja',
  abUndZu = 'Ab und zu',
  imNotfall = 'Im Notfall',
  nein = 'Nein',
}
export enum MontageArbeitgeber {
  SEHR_OFT = 'SEHR OFT',
  HAEUFIG = 'HAEUFIG',
  SELTEN = 'SELTEN',
  NIE = 'NIE',
}
